import Swiper from 'swiper';
import 'swiper/css';
import { Pagination , Navigation } from 'swiper/modules';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function productsCarousel() {
  const carousels = document.querySelectorAll('.products_carousel');

  carousels.forEach((carousel, index) => {
    const swiperCarousel =  carousel.querySelector('.productsCarousel')
    const swiper = new Swiper(swiperCarousel, {
      modules: [Pagination, Navigation],
      slidesPerView: 4,
      slidesPerGroup: 4, // Кількість слайдів, які будуть прокручуватися за раз
      spaceBetween: 30,
      grabCursor:true,
      pagination: {
        el: carousel.querySelector('.swiper-pagination'),
        type: "progressbar",
      },
      navigation: {
        nextEl: carousel.querySelector('.swiper-button-next'),
        prevEl: carousel.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        0: {
          slidesPerGroup: 1,
          slidesPerView: 1.5,
          spaceBetween: 6
        },
        375: {
          slidesPerGroup: 1,
          slidesPerView: 1.5,
          spaceBetween: 10
        },
        400: {
          slidesPerGroup: 1,
          slidesPerView: 2.1,
          spaceBetween: 10
        },
        420: {
          slidesPerGroup: 1,
          slidesPerView: 2.3,
          spaceBetween: 10
        },
        480: {
          slidesPerGroup: 1,
          slidesPerView: 2.8,
          spaceBetween: 20
        },
        560: {
          slidesPerGroup: 1,
          slidesPerView: 3.1,
          spaceBetween: 20
        },
        640: {
          slidesPerGroup: 1,
          slidesPerView: 3,
          spaceBetween: 20
        },
        768: {
          slidesPerGroup: 1,
          slidesPerView: 2.8,
          spaceBetween: 20
        },
        1024: {
          slidesPerGroup: 1,
          slidesPerView: 4,
          spaceBetween: 30
        }
      },
      // on: {
      //   init: function () {
      //     const secondaryPagination = carousel.querySelector('.swiper-nav-bullets');
      //     const totalSlides = this.slides.length-this.params.slidesPerView+this.params.slidesPerGroup; // Враховуйте всі слайди
      //     const slidesPerGroup = this.params.slidesPerGroup;
      //     const totalGroups = Math.ceil(totalSlides / slidesPerGroup); // Визначити кількість груп
      //     let bulletsHTML = '';
      //     for (let i = 0; i < totalGroups; i++) {
      //       bulletsHTML += `<span class="swiper-pagination-bullet" data-index="${i * slidesPerGroup}">${i + 1}</span>`;
      //     }
      //     secondaryPagination.innerHTML = bulletsHTML;

      //     // Додавання обробників подій для другої пагінації
      //     const bullets = secondaryPagination.querySelectorAll('.swiper-pagination-bullet');
      //     bullets.forEach((bullet, index) => {
      //       bullet.addEventListener('click', () => {
      //         swiper.slideTo(index * slidesPerGroup);
      //       });
      //     });
      //     if(bullets && bullets[0]) bullets[0].classList.add('swiper-pagination-bullet-active');
      //     // Встановити активний буллет
      //     for (let i = 1; i < 5; i++) {
      //       if (!bullets[i]) {
      //         break;
      //       }
      //       bullets[i].classList.add('swiper-pagination-bullet-next');
      //     }

      //   },

      //   slideChange: function () {
      //     const activeIndex = Math.ceil(this.activeIndex/this.params.slidesPerGroup);
      //     const secondaryPagination = carousel.querySelector('.swiper-nav-bullets');
      //     const bullets = secondaryPagination.querySelectorAll('.swiper-pagination-bullet');
      //     if(bullets && bullets[0]){
      //       bullets.forEach(bullet => bullet.classList.remove('swiper-pagination-bullet-active'));
      //       if(bullets && bullets[activeIndex]){
      //         bullets[activeIndex].classList.add('swiper-pagination-bullet-active');
      //       }
      //       let markIndex = -2;
      //       while (markIndex + activeIndex < 0) {
      //         markIndex++;
      //       }
      //       while (markIndex + 4 + activeIndex >= bullets.length) {
      //         markIndex--;
      //       }
      //       bullets.forEach((bullet, index) => {
      //         if (index >= activeIndex + markIndex && index <= activeIndex + markIndex + 4) {
      //           bullets[index].classList.add(`swiper-pagination-bullet-${index-activeIndex < 0 ? 'next' : 'prev'}`);
      //         } else {
      //           bullet.classList.remove('swiper-pagination-bullet-prev', 'swiper-pagination-bullet-next');
      //         }
      //       });
      //     }

      //   },
      // },
    });
  });
}
